<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    open-delay="300"
    persistent
    scrollable
    transition="dialog-top-transition"
  >
    <template #activator="{ on }">
      <v-chip
        v-if="!!item.offer"
        class="font-weight-bold"
        color="primary"
        label
        v-on="on"
        v-text="subtotal"
      />
      <v-chip v-else color="grey lighten-2" label v-text="total" />
    </template>

    <v-card>
      <v-toolbar color="grey lighten-3">
        <v-toolbar-title>
          <span v-text="$t('details')" />
          <span class="ml-4 font-weight-bold" v-text="item.name" />
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text v-if="dialog" class="pt-4">
        <v-row align="center">
          <v-col cols="6">
            <div class="text-subtitle-1" v-text="$t('description')" />
          </v-col>
          <v-col cols="6">
            <form-field-text
              v-model="sDescription"
              :disabled="disabled"
              label="description"
              rules="max:255"
            />
          </v-col>
        </v-row>

        <v-divider class="my-4" />

        <v-row
          v-for="(obItem, index) in items"
          align="center"
          :key="`item.details.${index}`"
        >
          <v-col cols="6">
            <div
              class="text-subtitle-1"
              :class="obItem.important ? 'font-weight-bold' : ''"
              v-text="$t(obItem.label)"
            />
          </v-col>
          <v-col cols="6">
            <div
              class="font-weight-bold"
              :class="obItem.important ? 'text-h6' : 'text-subtitle-2'"
              v-text="obItem.value"
            />
            <div
              v-if="obItem.title"
              class="text-subtitle-2"
              v-text="obItem.title"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="close">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import type { InvoicePosition, TaxType } from "@planetadeleste/vue-mc-gw";

@Component
export default class ItemRowDetails extends Vue {
  @VModel({ type: Boolean, default: false }) dialog!: boolean;
  @Prop(Object) readonly item!: InvoicePosition;
  @Prop(Object) readonly taxType!: TaxType;
  @Prop(Boolean) readonly disabled!: boolean;

  @Prop([String, Number]) readonly tax!: string;
  @Prop([String, Number]) readonly subtotal!: string;
  @Prop([String, Number]) readonly subtotalWithoutTax!: string;
  @Prop([String, Number]) readonly total!: string;
  @Prop([String, Number]) readonly discount!: string;

  get items() {
    const arItems: Record<string, any>[] = [];

    if (this.taxType && this.taxType.name) {
      arItems.push({
        label: "tax.type",
        value: this.taxType.name,
        title: this.taxPercent,
      });
    }

    if (this.discount) {
      arItems.push({ label: "discount", value: this.discount });
    }

    if (this.subtotalWithoutTax) {
      arItems.push({ label: "subtotal", value: this.subtotalWithoutTax });
    }

    if (this.tax) {
      arItems.push({ label: "tax.price", value: this.tax });
    }

    if (this.total) {
      arItems.push({
        label: "total.price",
        value: this.total,
        important: true,
      });
    }

    return arItems;
  }

  get sDescription(): string {
    return this.item.get("description");
  }

  set sDescription(sValue: string) {
    this.item.set("description", sValue);
  }

  get taxPercent() {
    return this.taxType ? `${this.taxType.percent}%` : "";
  }

  close() {
    this.$emit("input", false);
  }
}
</script>
